<template>
  <div>

    <v-card class="card_section">
      <v-card-text>
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <h2>Ogólne</h2>
            <v-checkbox
              v-for="item in roles.head"
              :key="item.id"
              v-model="selected_roles.head"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <h2>Widok panelu</h2>
            <v-checkbox
              v-for="item in roles.dashboard"
              :key="item.id"
              v-model="selected_roles.dashboard"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <h2>Projekt</h2>
            <v-checkbox
              v-for="item in roles.project"
              :key="item.id"
              v-model="selected_roles.project"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <h2>Etapy</h2>
            <v-checkbox
              v-for="item in roles.sprint"
              :key="item.id"
              v-model="selected_roles.sprint"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <h2>Zadania</h2>
            <v-checkbox
              v-for="item in roles.task"
              :key="item.id"
              v-model="selected_roles.task"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>
          
          <v-col cols="12" md="6" lg="3">
            <h2>Koszty</h2>
            <v-checkbox
              v-for="item in roles.project_cost"
              :key="item.id"
              v-model="selected_roles.project_cost"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" md="6" lg="3">
            <h2>Faktury</h2>
            <v-checkbox
              v-for="item in roles.invoice"
              :key="item.id"
              v-model="selected_roles.invoice"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <h2>Zgłoszenia</h2>
            <v-checkbox
              v-for="item in roles.issue"
              :key="item.id"
              v-model="selected_roles.issue"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <h2>Pakiety</h2>
            <v-checkbox
              v-for="item in roles.package"
              :key="item.id"
              v-model="selected_roles.package"
              :value="item.id"
              :label="item.name"
              @change="update_selected()"
            ></v-checkbox>
          </v-col>

          <!-- <v-card>
            <v-card-text>
              <v-row>
                <v-col cols="12" md="4">
                  <h2>Uprawnienia: Klienci</h2>
                  <v-checkbox
                    v-for="item in container_user.users"
                    :key="item.id"
                    v-model="selected_roles.package"
                    :value="item.id"
                    :label="item.name"
                    @change="update_selected()"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <h2>Uprawnienia: Użytkownicy</h2>
                  <v-checkbox
                    v-for="item in roles.package"
                    :key="item.id"
                    v-model="selected_roles.package"
                    :value="item.id"
                    :label="item.name"
                    @change="update_selected()"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                  <h2>Uprawnienia: Podwykonawcy</h2>
                  <v-checkbox
                    v-for="item in roles.package"
                    :key="item.id"
                    v-model="selected_roles.package"
                    :value="item.id"
                    :label="item.name"
                    @change="update_selected()"
                  ></v-checkbox>
                </v-col>

              </v-row>
            </v-card-text>

          </v-card> -->
        </v-row>
      </v-card-text>
    </v-card>
    
  </div>
</template>

<script>
export default {
  props: {
    id: Number
  },
  data: () => ({
    roles: {
      head: [],
      dashboard: [],
      project: [],
      sprint: [],
      task: [],
      project_cost: [],
      invoice: [],
      issue: [],
      package: [],
    },

    selected_roles: {
      head: [],
      dashboard: [],
      project: [],
      sprint: [],
      task: [],
      project_cost: [],
      invoice: [],
      issue: [],
      package: [],
    },

    selected_permissions: [],
  }),
  methods: {
    // Zwracanie wybranych permissionow
    update_selected(){
      this.selected_permissions = [];

      for(const item in this.selected_roles.head){
        this.selected_permissions.push(this.selected_roles.head[item]);
      }

      // Mozna tylko 1 zaznaczyc - zabezpieczenie dla widoku
      if(this.selected_roles.dashboard.length > 1)
        this.selected_roles.dashboard = [this.selected_roles.dashboard[this.selected_roles.dashboard.length-1]]
      
      for(const item in this.selected_roles.dashboard){
        this.selected_permissions.push(this.selected_roles.dashboard[item]);
      }
      for(const item in this.selected_roles.project){
        this.selected_permissions.push(this.selected_roles.project[item]);
      }
      for(const item in this.selected_roles.sprint){
        this.selected_permissions.push(this.selected_roles.sprint[item]);
      }
      for(const item in this.selected_roles.task){
        this.selected_permissions.push(this.selected_roles.task[item]);
      }
      for(const item in this.selected_roles.project_cost){
        this.selected_permissions.push(this.selected_roles.project_cost[item]);
      }
      for(const item in this.selected_roles.invoice){
        this.selected_permissions.push(this.selected_roles.invoice[item]);
      }
      for(const item in this.selected_roles.issue){
        this.selected_permissions.push(this.selected_roles.issue[item]);
      }
      for(const item in this.selected_roles.package){
        this.selected_permissions.push(this.selected_roles.package[item]);
      }

      this.$emit('selected',this.selected_permissions);
    },

    // Sortowanie uprawnien
    // if (pokazywanie) wszystkich uprawnien jakie zwraca backend
    // else zaznaczanie roli
    sort_permissions(role_cat,prepare_role,sort_only){
      console.log(role_cat);
      switch(role_cat) {
        case 'dashboard': 
          if(sort_only) this.roles.dashboard.push(prepare_role);
          else this.selected_roles.dashboard.push(prepare_role);
          break;
        case 'project': 
          if(sort_only) this.roles.project.push(prepare_role);
          else this.selected_roles.project.push(prepare_role);
          break;
        case 'sprint': 
          if(sort_only) this.roles.sprint.push(prepare_role);
          else this.selected_roles.sprint.push(prepare_role);
          break;
        case 'task': 
          if(sort_only) this.roles.task.push(prepare_role);
          else this.selected_roles.task.push(prepare_role);
          break;
        case 'project_cost': 
          if(sort_only) this.roles.project_cost.push(prepare_role);
          else this.selected_roles.project_cost.push(prepare_role);
          break;
        case 'invoice': 
          if(sort_only) this.roles.invoice.push(prepare_role);
          else this.selected_roles.invoice.push(prepare_role);
          break;
        case 'issue': 
          if(sort_only) this.roles.issue.push(prepare_role);
          else this.selected_roles.issue.push(prepare_role);
          break;
        case 'package': 
          if(sort_only) this.roles.package.push(prepare_role);
          else this.selected_roles.package.push(prepare_role);
          break;
        default:
          if(sort_only) this.roles.head.push(prepare_role);
          else this.selected_roles.head.push(prepare_role);
      }
    }
  },
  mounted(){
    this.$store.commit('loader');

    let loader = 2;
    // Pobieranie uprawnień dla wybranej roli o ile przesylamy id
    console.log(this.id);
    if(this.id){
      this.$axios({url: this.$store.state.api +'/auth/role/'+this.id, data: {}, method: 'GET' })
        .then(resp => {
          this.user_data = resp.data;
          // Tworzenie listy ID przyznanych uprawnien dla uzytkownika
          for(let item in resp.data.permissions) {
            let role_cat = resp.data.permissions[item].slug.split('.')[0];
            let prepare_role = resp.data.permissions[item].permission_id;
            this.sort_permissions(role_cat,prepare_role,0);
            // this.selected_permissions.push(resp.data.permissions[item].permission_id);
          }
          console.log(this.prepare_role);
          console.log('Przygotowane role wyżej')

          // Zwracanie wybranych permissionow na start
          this.$emit('selected',this.selected_permissions);
        })
        .catch(err => {
          this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
        })
        .then (() => {
          loader--;
          if(!loader)this.$store.commit('loader_off');
        })
    }else loader--;

    this.$axios({url: this.$store.state.api +'/auth/permission_all', data: {}, method: 'GET' })
      .then(resp => {
        // Rozdzielanie na kategorie wszystkich uprawnien
        for(let item in resp.data) {
          let role_cat = resp.data[item].slug.split('.')[0];
          let prepare_role = {
            id: resp.data[item].id,
            name: resp.data[item].name
          }

          this.sort_permissions(role_cat,prepare_role,1);
          
        }
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.card_section {
  margin-top: 25px;
}
.classic_select {
  width: 250px;
}

.v-input--checkbox {
  margin-top: 0px !important;
}
h2 {
  margin-top: 10px;
  margin-bottom: 15px;
}
</style>