<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_user()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

        </v-card>
        
        <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>

                  <v-text-field
                    v-model="name"
                    label="Nazwa roli"
                    color="primary"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

        <Permissions 
          :id="0"
          @selected="update_selected"
        />

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
import Permissions from '@/components/Role/Permissions.vue';
export default {
  components: {
    Permissions
  },
  data: () => ({
    valid: false,
    id: 0,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista ról',
        disabled: false,
        to: '/role',
      },
      {
        text: 'Dodawanie roli',
        disabled: true,
        to: '/rola-nowa',
      }
    ],

    name: '',
    selected_permissions: []
  }),
  methods: {
    update_selected(value){
      console.log(value);
      this.selected_permissions = value;
    },
    add_user() {
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');

        let formData = new FormData();
        formData.append("name", this.name);
        for(let index in this.selected_permissions){
          formData.append("permission_id["+index+"]", this.selected_permissions[index]);
        }

        this.$axios({url: this.$store.state.api +'/auth/role', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/role');
            this.$store.commit("snackbar", {
              text: "Dodano rolę",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
            this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
  },
  mounted(){
  }
};
</script>

<style lang="scss" scoped>
</style>